@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Keania+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Mono:wght@400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@600&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000;
  color: white;

  touch-action: none; /* Disable all touch actions by default */
  overflow: hidden;
  cursor: none;
}

button {
  cursor: none;
}

.draggable {
  touch-action: manipulation; /* Enable touch manipulation for draggable elements */
}

code {
  font-family: source-code-pro, Arial, Helvetica, sans-serif,
    sans-serif;
}

h1 {
  font-size: 2rem;
  font-weight: 600;
}

h2 {
  font-size: 1.7rem;
  font-weight: 600;
}

h3 {
  font-size: 1.4rem;
  font-weight: 600;
}

